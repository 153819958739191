import Image from '../../components_sunnyside/image';

export function TrackingItemSVGIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.980469 5.17208C0.980469 2.67536 3.00446 0.651367 5.50118 0.651367H7.19645C9.69317 0.651367 11.7172 2.67536 11.7172 5.17208V6.86735C11.7172 9.36407 9.69317 11.3881 7.19645 11.3881H5.50118C3.00446 11.3881 0.980469 9.36407 0.980469 6.86735V5.17208ZM13.4116 17.608C13.4116 15.1113 15.4356 13.0873 17.9324 13.0873H19.6276C22.1243 13.0873 24.1483 15.1113 24.1483 17.608V19.3032C24.1483 21.8 22.1244 23.824 19.6276 23.824H17.9324C15.4356 23.824 13.4116 21.8 13.4116 19.3032V17.608ZM0.980469 17.608C0.980469 15.1113 3.00446 13.0873 5.50118 13.0873H7.19645C9.69317 13.0873 11.7172 15.1113 11.7172 17.608V19.3032C11.7172 21.8 9.69317 23.824 7.19645 23.824H5.50118C3.00446 23.824 0.980469 21.8 0.980469 19.3032V17.608ZM24.6819 4.0132C25.1331 3.56201 25.1331 2.83049 24.6819 2.3793C24.2307 1.92811 23.4992 1.92811 23.048 2.3793L18.2141 7.21324L16.7707 5.76984C16.3195 5.31865 15.5879 5.31865 15.1368 5.76984C14.6856 6.22103 14.6856 6.95255 15.1368 7.40374L16.5802 8.84714C17.4825 9.74952 18.9456 9.74952 19.848 8.84714L24.6819 4.0132Z" fill="white"/>
        </svg>

    );
}

export function ProgressItemSVGIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4297 7.23633H14.1036C13.5603 7.23633 13.0817 6.95438 12.8547 6.50055C12.6276 6.04672 12.7062 5.5292 13.0599 5.14989L13.0721 5.13701L17.5965 0.495036C17.757 0.330391 17.9861 0.236328 18.2266 0.236328C18.467 0.236328 18.6961 0.330391 18.8566 0.495036L23.381 5.13701C23.3852 5.14124 23.3893 5.14556 23.3933 5.14989C23.7469 5.52915 23.8255 6.04672 23.5985 6.50055C23.3714 6.95438 22.8928 7.23633 22.3495 7.23633H21.0297V22.2371C21.0297 23.3417 20.1343 24.2371 19.0297 24.2371H17.4297C16.3251 24.2371 15.4297 23.3417 15.4297 22.2371V7.23633ZM2.27344 14.6367C1.16887 14.6367 0.273438 15.5321 0.273438 16.6367V22.2367C0.273438 23.3413 1.16887 24.2367 2.27344 24.2367H3.87344C4.97801 24.2367 5.87344 23.3413 5.87344 22.2367V16.6367C5.87344 15.5321 4.97801 14.6367 3.87344 14.6367H2.27344ZM7.85156 12.6367C7.85156 11.5321 8.74699 10.6367 9.85156 10.6367H11.4516C12.5561 10.6367 13.4516 11.5321 13.4516 12.6367V22.2367C13.4516 23.3413 12.5561 24.2367 11.4516 24.2367H9.85156C8.74699 24.2367 7.85156 23.3413 7.85156 22.2367V12.6367Z" fill="white"/>
        </svg>

    );
}

export function ChallengeSVGIcon(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
            <path d="M21.2308 2.00631H20.1374C19.7552 0.934156 18.7398 0.160156 17.5385 0.160156H6.46154C5.25969 0.160156 4.24477 0.934156 3.86262 2.00631H2.76923C1.242 2.00631 0 3.24831 0 4.77554V6.62169C0 8.86477 1.61031 10.7349 3.73477 11.148C4.12385 15.0346 7.20323 18.1366 11.0769 18.5672V20.4678H8.30769C7.79815 20.4678 7.38462 20.8809 7.38462 21.3909C7.38462 21.9009 7.79815 22.314 8.30769 22.314H15.6923C16.2018 22.314 16.6154 21.9009 16.6154 21.3909C16.6154 20.8809 16.2018 20.4678 15.6923 20.4678H12.9231V18.5672C16.7968 18.1366 19.8762 15.0346 20.2652 11.148C22.3897 10.7349 24 8.86477 24 6.62169V4.77554C24 3.24831 22.758 2.00631 21.2308 2.00631ZM1.84615 6.62169V4.77554C1.84615 4.266 2.26015 3.85246 2.76923 3.85246H3.69231V9.22062C2.62015 8.83846 1.84615 7.82354 1.84615 6.62169ZM22.1538 6.62169C22.1538 7.82354 21.3798 8.83846 20.3077 9.22062V3.85246H21.2308C21.7398 3.85246 22.1538 4.266 22.1538 4.77554V6.62169Z" fill="white"/>
        </svg>
    );
}

export function CoachingSVGIcon(): JSX.Element {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.234 0.897998H20.9382C21.0632 1.25066 21.1201 1.64887 21.1201 2.05818C21.1201 4.25359 19.3231 6.02775 17.1392 6.02775H10.8606C8.66549 6.02775 6.89106 4.25359 6.89106 2.05818C6.89106 1.64887 6.94799 1.25066 7.07309 0.897998H6.76596C4.86643 0.897998 3.3197 2.43362 3.3197 4.33301V24.1922C3.3197 26.1033 4.86643 27.65 6.76596 27.65H21.234C23.1334 27.65 24.6802 26.1033 24.6802 24.1922V4.33301C24.6802 2.43362 23.1334 0.897998 21.234 0.897998ZM19.8348 22.2701H13.8294C13.2038 22.2701 12.7034 21.7697 12.7034 21.144C12.7034 20.5184 13.2038 20.0066 13.8294 20.0066H19.8348C20.4491 20.0066 20.9609 20.5184 20.9609 21.144C20.9609 21.7697 20.4491 22.2701 19.8348 22.2701ZM19.8348 15.4797H13.8294C13.2038 15.4797 12.7034 14.9793 12.7034 14.3537C12.7034 13.7281 13.2038 13.2163 13.8294 13.2163H19.8348C20.4491 13.2163 20.9609 13.7281 20.9609 14.3537C20.9609 14.9793 20.4491 15.4797 19.8348 15.4797ZM10.5036 21.188C10.5036 22.1792 9.70005 22.9828 8.70877 22.9828C7.71749 22.9828 6.9139 22.1792 6.9139 21.188C6.9139 20.1967 7.71749 19.3931 8.70877 19.3931C9.70005 19.3931 10.5036 20.1967 10.5036 21.188ZM8.70877 16.069C9.70005 16.069 10.5036 15.2654 10.5036 14.2741C10.5036 13.2828 9.70005 12.4792 8.70877 12.4792C7.71749 12.4792 6.9139 13.2828 6.9139 14.2741C6.9139 15.2654 7.71749 16.069 8.70877 16.069Z" fill="white"/>
            <path d="M18.8485 2.05896C18.8485 3.00279 18.0834 3.76791 17.1396 3.76791H10.8583C9.91452 3.76791 9.14939 3.00279 9.14939 2.05896C9.14939 1.11513 9.91452 0.350006 10.8583 0.350006H17.1396C18.0834 0.350006 18.8485 1.11513 18.8485 2.05896Z" fill="white"/>
        </svg>
    );
}

export function CommunitySVGIcon(): JSX.Element {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.76605 18.7758C7.38719 18.3815 6.72379 18.3325 6.29078 18.6613L6.2794 18.654L5.29945 19.6339C5.08459 19.8481 4.96678 20.1339 4.96678 20.4378C4.95386 21.0498 5.4927 21.5874 6.10402 21.5751C6.45992 21.5808 6.77687 21.4029 7.009 21.1413L7.76605 20.3843C7.92469 20.2256 8.03112 20.0282 8.07529 19.8133C8.15461 19.4417 8.03734 19.0431 7.76605 18.7758Z" fill="white"/>
            <path d="M5.04254 15.4446C4.59895 15.001 3.87819 15.0014 3.43407 15.4446C3.33085 15.5484 2.69442 16.1844 2.57662 16.3027C1.55363 17.4129 3.07361 18.9335 4.18442 17.9112C4.18442 17.9112 4.92861 17.1669 5.04254 17.0531C5.48639 16.61 5.48639 15.8884 5.04254 15.4446Z" fill="white"/>
            <path d="M10.0924 21.3699C9.78854 21.3699 9.50272 21.4884 9.28786 21.7032L8.42973 22.5613C8.42572 22.5654 8.4217 22.5694 8.41769 22.5741C7.98769 22.9989 7.99411 23.7512 8.42973 24.1698C8.87352 24.6129 9.59509 24.6129 10.0382 24.1698L10.897 23.311C11.6083 22.6016 11.097 21.3661 10.0924 21.3699Z" fill="white"/>
            <path d="M17.7247 8.74623C15.969 9.85335 13.6148 9.64317 12.0867 8.11503L10.0974 6.12636L12.58 3.64371C9.91348 0.651338 5.22461 0.461508 2.38626 3.31914C-0.467489 6.15347 -0.284553 10.8368 2.70153 13.5041C4.87614 12.2887 7.5503 13.9863 7.37433 16.4627C8.81426 16.6183 10.0333 17.9234 10.0993 19.3637C11.4533 19.3347 12.7984 20.3488 13.1281 21.6863C14.2554 21.3842 15.5188 21.7585 16.2909 22.6516L16.3136 22.6248C17.4329 23.2968 18.617 21.9165 17.7728 20.9112L17.7748 20.9086C17.7574 20.8918 13.2547 16.389 13.2547 16.389C13.6085 16.0352 14.3206 15.3231 14.6744 14.9693L19.143 19.438C20.2531 20.4608 21.7741 18.9398 20.7508 17.8295L16.2828 13.3609C16.6367 13.007 17.3488 12.2949 17.7026 11.9411C18.7069 12.9454 21.1433 15.382 22.1712 16.4098C22.5944 16.8506 23.3564 16.8504 23.7797 16.4098C24.2228 15.966 24.2228 15.2444 23.7797 14.8013L17.7247 8.74623Z" fill="white"/>
            <path d="M25.6135 3.3192C22.8926 0.598922 18.4654 0.598922 15.7444 3.3192C15.2154 3.8486 13.4375 5.62689 12.9378 6.1265L13.5068 6.69545C14.4961 7.68477 16.1052 7.68477 17.0939 6.69545L17.8041 5.98526C18.1817 6.367 25.1128 13.2863 25.3076 13.4948C28.2865 10.8265 28.463 6.14986 25.6135 3.3192Z" fill="white"/>
            <path d="M14.7313 23.9241C14.2875 23.4809 13.5664 23.4804 13.1229 23.9241L12.2647 24.7822C11.8232 25.2055 11.8234 25.9673 12.2647 26.3907C12.6886 26.8313 13.4496 26.831 13.8732 26.3907L14.7313 25.5326C15.172 25.1098 15.172 24.3459 14.7313 23.9241Z" fill="white"/>
        </svg>
    );
}

export function ProfileItemSVGIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11780_10775)">
                <path d="M12 0.237305C5.383 0.237305 0 5.6203 0 12.2373C0 18.8543 5.383 24.2373 12 24.2373C18.617 24.2373 24 18.8543 24 12.2373C24 5.6203 18.617 0.237305 12 0.237305ZM18.698 19.6413C18.034 17.6683 16.198 16.2373 14 16.2373H10C7.802 16.2373 5.968 17.6693 5.304 19.6423C3.281 17.8113 2 15.1743 2 12.2373C2 6.7233 6.486 2.2373 12 2.2373C17.514 2.2373 22 6.7233 22 12.2373C22 15.1733 20.72 17.8103 18.698 19.6413Z" fill="white"/>
                <path d="M11.8949 6.47949C9.74396 6.47949 8 8.02968 8 9.94167V10.8072C8 12.7192 9.74396 14.2694 11.8949 14.2694C14.0459 14.2694 15.7899 12.7192 15.7899 10.8072V9.94167C15.7899 8.02968 14.0459 6.47949 11.8949 6.47949Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_11780_10775">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.237305)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export const AndroidBadge = ({ height = 60 }: { height?: number }): JSX.Element => (
    <a
        href='https://play.google.com/store/apps/details?id=co.sunnyside&hl=en&gl=US'
        target='_blank'
        rel="noreferrer"
    >
        <Image
            src={'/img/app-install/google-play-badge.png'}
            height={height}
            width={201/60 * height}
        />
    </a>
);

export const IOSBadge = ({ height = 60 }: { height?: number }): JSX.Element => (
    <a
        href='https://apps.apple.com/us/app/sunnyside-mindful-drinking/id1593489083'
        target='_blank'
        rel="noreferrer"
    >
        <Image
            src={'/img/app-install/app-store-badge.png'}
            height={height}
            width={180/60 * height}
        />
    </a>
);
